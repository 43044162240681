import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { OperatorData } from "@/@types/management";
interface IAuthModule {
  _me: OperatorData | null;
}

@Module({
  dynamic: true,
  store,
  name: "auth",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).auth,
})
class AuthModule extends VuexModule implements IAuthModule {
  public _me: OperatorData | null = null;
  public _beforeChangePassRoot = "";

  /**
   * 変更
   * @param operator
   */
  @Mutation
  public changeMe(operator: OperatorData | null) {
    this._me = operator;
  }

  /**
   * 変更
   * @param routeName
   */
  @Mutation
  public changePassRoot(routeName: string) {
    this._beforeChangePassRoot = routeName;
  }

  public get me() {
    return this._me || null;
  }
  public get beforeChangePassRoot() {
    return this._beforeChangePassRoot || "";
  }
}
export default getModule(AuthModule);

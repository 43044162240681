import axios from "@/plugins/axios";
import { CreateHospital, CreateAnnouncement, CreateOperator } from "@/@types/management";
import { UpdateCourseInfo } from "@/@types/course";
import {
  AddCustomer,
  AddPet,
  CustomerData,
  NewReservation,
  PatientData,
  ReservationData,
  SearchReservationParams,
} from "@/@types/reservation";

class FacilityService {
  //病院情報CRUD START
  get(id: string): Promise<any> {
    return axios.get(`/management/facilities/${id}`);
  }

  getAll(): Promise<any> {
    return axios.get(`/management/facilities`);
  }

  create(data: CreateHospital): Promise<any> {
    return axios.post(`/management/facilities`, data);
  }

  update(id: string, data: CreateHospital): Promise<any> {
    return axios.put(`/management/facilities/${id}`, data);
  }
  delete(id: string): Promise<any> {
    return axios.delete(`/management/facilities/${id}`);
  }

  // 病院の顧客取得
  getCustomersPatients(hospital_id: string): Promise<any> {
    return axios.get(`/management/facilities/${hospital_id}/customers_patients`);
  }

  //コースCRUD
  getCourses(id: string): Promise<any> {
    return axios.get(`/management/facilities/${id}/courses`);
  }
  getCourseGroups(id: string): Promise<any> {
    return axios.get(`/management/facilities/${id}/course_groups`);
  }
  createCourse(id: string, name: string): Promise<any> {
    return axios.post(`/management/facilities/${id}/courses`, { name });
  }
  createCourseGroup(id: string, courseGroupReq: { course_ids: string[]; name?: string }): Promise<any> {
    return axios.post(`/management/facilities/${id}/course_groups`, courseGroupReq);
  }
  preCheckCourseGroup(id: string, course_ids: string[]): Promise<any> {
    return axios.post(`/management/facilities/${id}/course_groups/precheck`, { course_ids });
  }
  updateCourse(hospital_id: string, course_id: string, data: UpdateCourseInfo): Promise<any> {
    return axios.put(`/management/facilities/${hospital_id}/courses/${course_id}`, data);
  }
  updateCourseGroup(
    hospital_id: string,
    course_group_id: string,
    courseGroupReq: { course_ids: string[]; name?: string }
  ): Promise<any> {
    return axios.put(`/management/facilities/${hospital_id}/course_groups/${course_group_id}`, courseGroupReq);
  }
  deleteCourse(hospital_id: string, course_id: string): Promise<any> {
    return axios.delete(`/management/facilities/${hospital_id}/courses/${course_id}`);
  }
  deleteCourseGroup(hospital_id: string, course_group_id: string): Promise<any> {
    return axios.delete(`/management/facilities/${hospital_id}/course_groups/${course_group_id}`);
  }
  getCourseDetail(hospital_id: string, course_id: string): Promise<any> {
    return axios.get(`/management/facilities/${hospital_id}/courses/${course_id}`);
  }

  //予約CRUD
  createReservation(hospital_id: string, data: NewReservation): Promise<any> {
    return axios.post(`/management/facilities/${hospital_id}/reservations`, data);
  }
  createSpecialReservation(hospital_id: string, data: NewReservation): Promise<any> {
    return axios.post(`/management/facilities/${hospital_id}/reservations_special`, data);
  }
  getReservations(facility_id: string, params: any): Promise<any> {
    return axios.get(`/management/facilities/${facility_id}/reservations`, { params: params });
  }
  getReservationDetail(facility_id: string, reservation_id: string): Promise<any> {
    return axios.get(`/management/facilities/${facility_id}/reservations/${reservation_id}`);
  }
  updateReservation(facility_id: string, reservation_id: string, data: ReservationData): Promise<any> {
    return axios.put(`/management/facilities/${facility_id}/reservations/${reservation_id}`, data);
  }
  deleteReservation(facility_id: string, reservation_id: string): Promise<any> {
    return axios.delete(`/management/facilities/${facility_id}/reservations/${reservation_id}`);
  }
  //予約検索
  getConditionalSearchReservations(facility_id: string, data: SearchReservationParams): Promise<any> {
    return axios.get(`/management/facilities/${facility_id}/reservations/search`, { params: data });
  }

  //顧客CRUD
  createCustomer(hospital_id: string, data: AddCustomer): Promise<any> {
    return axios.post(`/management/facilities/${hospital_id}/customers`, data);
  }
  getCustomer(hospital_id: string, customer_id: string): Promise<any> {
    return axios.get(`/management/facilities/${hospital_id}/customers/${customer_id}`);
  }
  updateCustomer(hospital_id: string, customer_id: string, data: CustomerData): Promise<any> {
    return axios.put(`/management/facilities/${hospital_id}/customers/${customer_id}`, data);
  }
  //患者CRUD
  createPatient(hospital_id: string, data: AddPet): Promise<any> {
    return axios.post(`/management/facilities/${hospital_id}/patients`, data);
  }
  getPatient(hospital_id: string, patient_id: string): Promise<any> {
    return axios.get(`/management/facilities/${hospital_id}/patients/${patient_id}`);
  }
  updatePatient(hospital_id: string, patient_id: string, data: PatientData): Promise<any> {
    return axios.put(`/management/facilities/${hospital_id}/patients/${patient_id}`, data);
  }
  //運営アカウントCRUD
  createOperator(data: CreateOperator): Promise<any> {
    return axios.post(`/management/operators`, data);
  }
  updateOperator(operator_id: string, data: CreateOperator): Promise<any> {
    return axios.put(`/management/operators/${operator_id}`, data);
  }
  deleteOperator(operator_id: string): Promise<any> {
    return axios.delete(`/management/operators/${operator_id}`);
  }
  getOperators(): Promise<any> {
    return axios.get(`/management/operators`);
  }
  getOperatorsByFacilityId(facility_id: string): Promise<any> {
    return axios.get(`/management/operators`, { params: { facility_id } });
  }
  getOperatorById(operator_id: string): Promise<any> {
    return axios.get(`/management/operators/${operator_id}`);
  }
  getAnimalTypes(): Promise<any> {
    return axios.get("management/animal_types");
  }

  //予約可能枠取得
  getPossibleReservations(hospital_id: string, course_id: string, year: string, month: string, has_all?: string) {
    return axios.get(`membership/facilities/${hospital_id}/reservations`, {
      params: { course_id, year, month, has_all },
    });
  }

  // パスワードリセット一時リンク生成
  sendResetPasswordLink(email: string) {
    return axios.post("management/temporary_link/password", { email });
  }
  // パスワードリセット情報取得
  getResetPasswordInfo(id: string) {
    return axios.get(`management/temporary_link/password/${id}`);
  }

  //施設向けお知らせの新規作成
  createAnnouncement(data: CreateAnnouncement): Promise<any> {
    return axios.post(`/management/announcements`, data);
  }

  //施設向けお知らせの取得
  getAnnouncements(params?: any): Promise<any> {
    return axios.get(`/management/announcements`, { params });
  }
  //施設向けお知らせの更新
  updateAnnouncementById(id: string, data: CreateAnnouncement): Promise<any> {
    return axios.put(`/management/announcements/${id}`, data);
  }
  //施設向けお知らせの削除
  deleteAnnouncementById(id: string): Promise<any> {
    return axios.delete(`/management/announcements/${id}`);
  }
  //施設向けお知らせの既読
  readAnnouncement(cognito_id: string, announcement_id: string): Promise<any> {
    return axios.post(`/management/announcements/${announcement_id}/${cognito_id}/read`);
  }
}
export default new FacilityService();

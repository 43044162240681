import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
  // APIのURI
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // リクエストヘッダ
  headers: {
    "Content-type": "application/json",
    "x-api-key": process.env.VUE_APP_API_KEY || "",
  },
});

export default apiClient;
